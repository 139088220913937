<script setup>
import { useSettings } from "@/composables/UserSettings.js";
import { SETTING_HSFAI_ENABLED } from "@/config/constants.js";

const props = defineProps({
  iconActivator: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: "",
  },
  menuUp: {
    type: Boolean,
    default: false,
  },
  menuDown: {
    type: Boolean,
    default: true,
  },
});

const showAIOption = useSettings(SETTING_HSFAI_ENABLED, false);
</script>

<template>
  <v-menu :location="menuUp ? 'top' : 'bottom'">
    <template #activator="{ props: menuProps }">
      <!-- If Mobile mode, using just an icon -->
      <v-btn
        v-if="iconActivator"
        id="toolsMenu"
        :color="color"
        v-bind="menuProps"
      >
        <span>{{ $t("tools.title") }}</span>
        <v-icon icon="mdi-tools" />
      </v-btn>

      <!-- If Desktop mode, using just text -->
      <v-btn
        v-else
        id="toolsMenu"
        variant="flat"
        :color="color"
        v-bind="menuProps"
      >
        {{ $t("tools.title") }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        class="my-1"
        rounded="lg"
        :title="$t('tools.deadline-calculator')"
        :to="{ name: 'deadline-calculator' }"
      />
      <v-list-item
        class="my-1"
        rounded="lg"
        :title="$t('tools.terminology')"
        :to="{ name: 'terminology' }"
      />
      <v-list-item
        class="my-1"
        rounded="lg"
        :title="$t('tools.genesis')"
        :to="{ name: 'matter-groups' }"
      />
      <v-list-item
        v-if="$featureFlag.isVisible('showHSSTool')"
        class="my-1"
        rounded="lg"
        :to="{ name: 'hss' }"
        :title="$t('tools.hss').toUpperCase()"
      />
      <v-list-item
        v-if="$featureFlag.isVisible('showInvitation')"
        class="my-1"
        rounded="lg"
        :to="{ name: 'invitation' }"
        :title="$t('invitations.invitations')"
      />
      <v-list-item
        v-if="showAIOption"
        class="my-1"
        rounded="lg"
        :to="{ name: 'aiChat' }"
        :title="$t('common.ai.title')"
      />
    </v-list>
  </v-menu>
</template>
